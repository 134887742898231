.App {
  margin: auto;
  padding: 4px 0px


/*   padding: 4px; */
}

.cookies__privacy-polcy {
  color: whitesmoke;
  font-size: 24px;
}

.cookies__privacy-policy:visited {
  color: whitesmoke;
  font-size: 24px;
}

/* ################################################################
                                                                  #
  MEDIA QUERIES                                                   # 
                                                                  #
  #################################################################
   */

@media only screen and (max-width: 480px) {
  .App {
    width: 95%;
    margin: auto;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .App {
    width: 95%;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .App {
    width: 95%;
    margin: auto;
  }
}

