.joint800 {
  width: 100%;
  height: 8px;
  margin: auto;
}

/* ################################################################
                                                                  #
  MEDIA QUERIES                                                   # 
                                                                  #
  #################################################################
   */

@media only screen and (max-width: 480px) {
  .joint800 {
    width: 100%;
    height: 2px;
    margin: auto;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .joint800 {
    width: 100%;
    height: 4px;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .joint800 {
    width: 100%;
    height: 6px;
    margin: auto;
  }
}