.equaliser-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height:100%;
  width:300px;
  margin: auto;
}

.clearfix {
	zoom: 1;
	&:before,
	&:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
  }
	&:after {
    clear: both;
  }
}

.colour-bar {
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  height:18px;
  background: #AEED25;
}

.equaliser-column {
  width:32px;
  float:left;
  margin:0 6px;
  padding:0;
  height:180px;
  position:relative;
  list-style-type:none;
  background:#151515;
  &:nth-child(1) {
  .colour-bar {
			animation: color-bar var(--first-anim-value) 1s ease-out alternate infinite;
    }
  }
  &:nth-child(2) {
    .colour-bar {
			animation: color-bar var(--first-anim-value) 0.5s ease-out alternate infinite;
    }
  }
  &:nth-child(3) {
    .colour-bar {
			animation: color-bar var(--first-anim-value) 1.5s ease-out alternate infinite;
    }
  }
  &:nth-child(4) {
    .colour-bar {
			animation: color-bar var(--first-anim-value) 0.25s ease-out alternate infinite;
    }
  }
  &:nth-child(5) {
    .colour-bar {
			animation: color-bar var(--first-anim-value) 2s ease-out alternate infinite;
    }
  }
}
.led-mask {
  display:block;
  margin:0;
  position:relative;
  &:nth-child(even) {
    background:#212121;
    height:6px;
  }
  &:nth-child(odd) {
    height:12px;
    background:transparent;
    box-shadow:0 0 1px rgba(0,0,0,1),
    inset 0 0 8px rgba(0,0,0,0.9);
  }
}
@keyframes color-bar {
  0% { height:18px; background:#AEED25; }
  10% { height:90px; background:#AEED25; }
  20% { height:54px; background:#AEED25; }
  30% { height:126px; background:#AEED25; }
  40% { height:162px; background:#AEED25; }
  50% { height:54px; background:#AEED25; }
  60% { height:144px; background:#AEED25; }
  70% { height:90px; background:#AEED25; }
  80% { height:126px; background:#AEED25; }
  90% { height:36px; background:#AEED25;   }
  100% { height:18px; background:#AEED25; }
}

/*input[type="checkbox"]#track-control {
	~ equaliser-container .colour-bar {
      animation:paused;
    }
  &:checked {
    ~ equaliser-container .colour-bar {
      animation:running;
    }
  }
}*/

/* background: -webkit-linear-gradient(top, #f70c0c 0%,#f70c0c 18px,#a2d822 18px,#a2d822 100%); */



