.radio-player {
  margin: auto;
  width: 540px;  
  background-color: rgb(32, 32, 32);
  border-color: rgb(32, 32, 32);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px
}

.audioplayer-container {
  margin: 0px 0px;
  width: 500px;
  border-radius: 8px;
}

.audioplayer {
  margin: auto;
  width: 300px;
}

/* ################################################################
                                                                  #
  MEDIA QUERIES                                                   # 
                                                                  #
  #################################################################
   */

@media only screen and (max-width: 480px) {
  .radio-player {
    margin: auto;
    width: 85%;  
    background-color: rgb(32, 32, 32);
    border-color: rgb(32, 32, 32);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 12px
  }
  
  .audioplayer-container {
    margin: 0px 0px;
    width: 100%;
    border-radius: 8px;
  }
  
  .audioplayer {
    margin: auto;
    width: 180px;
  
  }


}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .radio-player {
    margin: auto;
    width: 90%;  
    background-color: rgb(32, 32, 32);
    border-color: rgb(32, 32, 32);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 12px
  }
  
  .audioplayer-container {
    margin: 0px 0px;
    width: 100%;
    border-radius: 8px;
  }
  
  .audioplayer {
    margin: auto;
    width: 180px;  
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .radio-player {
    margin: auto;
    width: 95%;  
    background-color: rgb(32, 32, 32);
    border-color: rgb(32, 32, 32);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 12px
  }
  
  .audioplayer-container {
    margin: 0px 0px;
    width: 100%;
    border-radius: 8px;
  }
  
  .audioplayer {
    margin: auto;
    width: 180px;  
  }
}