.tuner__container {
  width: 1200px;
  height: 280px;
  margin: auto;
  background-image: url("../../../assets/img/bg_tuner-surround_2.png");
  border-radius: 8px;
  position: sticky;
}

.tuner__main {
  width: 1200px;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tuner__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  padding: 8px;
  color: whitesmoke;
}

.tuner-carousel {
  margin: auto;
  width: 1200px;
  height: 160px;
  padding: 20px 0px 0px
  
}

.carousel-entry {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 32;
}  


/* .carousel-entry:hover{
  background-color: grey;
  transition-duration: 0.5s;
} */

.tuner-station__logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
}

.tuner-station__name {
  font-size: 16px;
  color: whitesmoke;
}

.tuner-filter__container {
  margin: auto;
  padding: 4px 0px 4px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}



.tuner-filter__item {
  font-size: 12px;
  font-weight: bold;
  color: whitesmoke;
  padding: 4px 10px;
  cursor: pointer
}


.filter__button {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  border-width: 0;
  padding: 0 4px 4px;
  width: 100px;
  box-sizing: border-box;
  background: transparent;
  font: inherit;
  cursor: pointer;
}

.filter__button-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  padding: 8px 16px;
  transform: translateY(0);
  text-align: center;
  color: whitesmoke;
  text-shadow: 0 -1px rgba(0, 0, 0, .25);
  transition-property: transform;
  transition-duration: .1s;
  -webkit-user-select: none;
  user-select: none;
}

.filter__button:active .filter__button-top {
  transform: translateY(4px);
}

.filter__button-top::after {
  content: '';
  position: absolute;
  z-index: -1;
  border-radius: 4px;
  width: 100%;
  height: 80%;
  box-sizing: content-box;
  background-color: black;
  text-align: center;
  color: #fff;
  box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, .2), 0 1px 2px 1px rgba(255, 255, 255, .2);
  transition-property: border-radius, padding, width, transform;
  transition-duration: .1s;
}

.filter__button:active .filter__button-top::after {
  border-radius: 6px;
  padding: 0 2px;
}

/* .filter__button-bottom {
  position: absolute;
  z-index: -1;
  bottom: 4px;
  left: 4px;
  border-radius: 8px / 16px 16px 8px 8px;
  padding-top: 6px;
  width: calc(100% - 8px);
  height: calc(100% - 10px);
  box-sizing: content-box;
  background-color: grey;
  background-image: radial-gradient(4px 8px at 4px calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(4px 8px at calc(100% - 4px) calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(16px at -4px 0, white, transparent), radial-gradient(16px at calc(100% + 4px) 0, white, transparent);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5), inset 0 -1px 3px 3px rgba(0, 0, 0, .4);
  transition-property: border-radius, padding-top;
  transition-duration: .2s;
}

.filter__button:active .filter__button-bottom {
  border-radius: 10px 10px 8px 8px / 8px;
  padding-top: 0;
}

.filter__button-base {
  position: absolute;
  z-index: -2;
  top: 4px;
  left: 0;
  border-radius: 12px;
  width: 100%;
  height: calc(100% - 4px);
  background-color: rgba(0, 0, 0, .15);
  box-shadow: 0 1px 1px 0 rgba(255, 255, 255, .75), inset 0 2px 2px rgba(0, 0, 0, .25);
}
 */
.tuner__ButtonGroup {
  z-index: 10;
  padding: 32px 0px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center
}

.tuner__button {
  padding: 0px 8px;
  cursor: pointer
}

/* ################################################################
                                                                  #
  MEDIA QUERIES                                                   # 
                                                                  #
  #################################################################
   */

@media only screen and (max-width: 480px) {
  .tuner__container {
    width: 100%;
    height: 280px;
    margin: auto;
    background-image: url("../../../assets/img/bg_tuner-surround_2.png");
    border-radius: 8px;
  }
  
  .tuner__main {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .tuner__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    padding: 8px;
    color: whitesmoke;
  }
  
  .tuner-carousel {
    margin: auto;
    width: 100%;
    height: 120px;
    padding: 20px 0px 0px
    
  }
  
  .carousel-entry {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 32;
  }  
  
  
  /* .carousel-entry:hover{
    background-color: grey;
    transition-duration: 0.5s;
  } */
  
  .tuner-station__logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .tuner-station__name {
    font-size: 12px;
    color: whitesmoke;
  }
  
  .tuner-filter__container {
    margin: auto;
    padding: 4px 0px 4px 0px;
    display: grid;
    grid-template-rows: 2;
    grid-template-columns: repeat(5, 1fr);;
    align-items: center;
  }
  
  .tuner-filter__item {
    font-size: 8px;
    font-weight: bold;
    color: whitesmoke;
    padding: 4px 4px;
    cursor: pointer
  }
  
  
  .filter__button {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    border-width: 0;
    padding: 0 4px 4px;
    width: 60px;
    box-sizing: border-box;
    background: transparent;
    font: inherit;
    cursor: pointer;
  }
  
  .filter__button-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    padding: 8px 16px;
    transform: translateY(0);
    text-align: center;
    color: whitesmoke;
    text-shadow: 0 -1px rgba(0, 0, 0, .25);
    transition-property: transform;
    transition-duration: .1s;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .filter__button:active .filter__button-top {
    transform: translateY(2px);
  }
  
  .tuner__ButtonGroup {
    display: none;
  }
  
  .tuner__button {
    padding: 0px 8px;
    cursor: pointer
  }


}


@media only screen and (min-width: 480px) and (max-width: 768px) {
  .tuner__container {
    width: 100%;
    height: 280px;
    margin: auto;
    background-image: url("../../../assets/img/bg_tuner-surround_2.png");
    border-radius: 8px;
  }
  
  .tuner__main {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .tuner__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    padding: 8px;
    color: whitesmoke;
  }
  
  .tuner-carousel {
    margin: auto;
    width: 100%;
    height: 120px;
    padding: 20px 0px 0px
    
  }
  
  .carousel-entry {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 32;
  }  
  
  
  /* .carousel-entry:hover{
    background-color: grey;
    transition-duration: 0.5s;
  } */
  
  .tuner-station__logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .tuner-station__name {
    font-size: 14px;
    color: whitesmoke;
  }
  
  .tuner-filter__container {
    margin: auto;
    padding: 4px 0px 4px 0px;
    display: grid;
    grid-template-rows: 2;
    grid-template-columns: repeat(5, 1fr);;
    align-items: center;
  }
  
  .tuner-filter__item {
    font-size: 10px;
    font-weight: bold;
    color: whitesmoke;
    padding: 4px 4px;
    cursor: pointer
  }
  
  
  .filter__button {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    border-width: 0;
    padding: 0 4px 4px;
    width: 80px;
    box-sizing: border-box;
    background: transparent;
    font: inherit;
    cursor: pointer;
  }
  
  .filter__button-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    padding: 8px 16px;
    transform: translateY(0);
    text-align: center;
    color: whitesmoke;
    text-shadow: 0 -1px rgba(0, 0, 0, .25);
    transition-property: transform;
    transition-duration: .1s;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .filter__button:active .filter__button-top {
    transform: translateY(2px);
  }
  
  .tuner__ButtonGroup {
    display: none;
  }
  
  .tuner__button {
    padding: 0px 8px;
    cursor: pointer
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {

  .tuner__container {
    width: 100%;
    height: 280px;
    margin: auto;
    background-image: url("../../../assets/img/bg_tuner-surround_2.png");
    border-radius: 8px;
  }
  
  .tuner__main {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .tuner__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    padding: 8px;
    color: whitesmoke;
  }
  
  .tuner-carousel {
    margin: auto;
    width: 100%;
    height: 140px;
    padding: 12px 0px 0px
    
  }
  
  .carousel-entry {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 32;
  }  
  
  
  /* .carousel-entry:hover{
    background-color: grey;
    transition-duration: 0.5s;
  } */
  
  .tuner-station__logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .tuner-station__name {
    font-size: 12px;
    color: whitesmoke;
  }
  
  .tuner-filter__container {
    margin: auto;
    padding: 4px 0px 4px 0px;
    display: grid;
    grid-template-rows: 2;
    grid-template-columns: repeat(5, 1fr);;
    align-items: center;
  }
  
  .tuner-filter__item {
    font-size: 12px;
    font-weight: bold;
    color: whitesmoke;
    padding: 4px 4px;
    cursor: pointer
  }
  
  
  .filter__button {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    border-width: 0;
    padding: 0 4px 4px;
    width: 120px;
    box-sizing: border-box;
    background: transparent;
    font: inherit;
    cursor: pointer;
  }
  
  .filter__button-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    padding: 8px 16px;
    transform: translateY(0);
    text-align: center;
    color: whitesmoke;
    text-shadow: 0 -1px rgba(0, 0, 0, .25);
    transition-property: transform;
    transition-duration: .1s;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .filter__button:active .filter__button-top {
    transform: translateY(2px);
  }
  
  .tuner__ButtonGroup {
    display: none;
  }
  
  .tuner__button {
    padding: 0px 8px;
    cursor: pointer
  }
}