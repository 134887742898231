.eq-player-section {
  margin: auto;
  width: 1200px;
  height: 240px;
  background-image: url("../../assets/img/bg_tuner-surround_2.png");
  display: grid;
  grid-template-columns: 325px 550px 325px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.eq_graph {
  width: 325px;
  display: flex;
  flex-direction: row;
  justify-content: center
}

/* ################################################################
                                                                  #
  MEDIA QUERIES                                                   # 
                                                                  #
  #################################################################
   */

@media only screen and (max-width: 480px) {
  .eq-player-section {
    width: 100%;
    height: 200px;
    background-image: url("../../assets/img/bg_tuner-surround_2.png");
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  
  .eq_graph {
    display: none;
/*     width: 325px;
    display: flex;
    flex-direction: row;
    justify-content: center */
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .eq-player-section {
    margin: auto;
    width: 100%;
    height: 200px;
    background-image: url("../../assets/img/bg_tuner-surround_2.png");
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  
  .eq_graph {
    display: none;
/*     width: 325px;
    display: flex;
    flex-direction: row;
    justify-content: center */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .eq-player-section {
    margin: auto;
    width: 100%;
    height: 200px;
    background-image: url("../../assets/img/bg_tuner-surround_2.png");
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  
  .eq_graph {
    display: none;

  }
}