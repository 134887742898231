@font-face {
  font-family: "alarm_clock";
  src: local("alarm_clock"), url(../../assets/font/alarm_clock.ttf), format("truetype");
}

.header-container {
  margin: auto;
  width: 1200px;
  height: 100px;
  background-image: url("../../assets/img/bg_tuner-surround_2.png");
  border-radius: 8px;
}

.header__top-row { 
  display: grid;
  align-items: top;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  padding: 8px 0px
}

.header__clock {
  font-family: alarm_clock, serif;
  padding: 0px 16px;
  text-align: center;
  font-size: 48px;
  color: whitesmoke;
}

.header__logo {
  padding: 0px 8px;
}

.header__logo > img {
  height: 84px;
  border-radius: 8px
}

.header__login {
  display:flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px 10px;
  color: whitesmoke;
}

.header__source-container {
  margin: auto;
  padding: 4px 0px 4px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__source-item {
  font-size: 14px;
  font-weight: bold;
  color: whitesmoke;
  padding: 4px 10px;
  cursor: pointer
}


.source__button {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  border-width: 0;
  padding: 0 4px 4px;
  width: 200px;
  box-sizing: border-box;
  background: transparent;
  font: inherit;
  cursor: pointer;
}  

.source__button-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  padding: 8px 16px;
  transform: translateY(0);
  text-align: center;
  color: whitesmoke;
  text-shadow: 0 -1px rgba(0, 0, 0, .25);
  transition-property: transform;
  transition-duration: .1s;
  -webkit-user-select: none;
  user-select: none;
}

.source__button:active .source__button-top {
  transform: translateY(4px);
}

.source__button-top::after {
  content: '';
  position: absolute;
  z-index: -1;
  border-radius: 4px;
  width: 100%;
  height: 80%;
  box-sizing: content-box;
  background-color: black;
  text-align: center;
  color: #fff;
  box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, .2), 0 1px 2px 1px rgba(255, 255, 255, .2);
  transition-property: border-radius, padding, width, transform;
  transition-duration: .1s;
}

.aource__button:active .source__button-top::after {
  border-radius: 6px;
  padding: 0 2px;
}

.source__button-bottom {
  position: absolute;
  z-index: -1;
  bottom: 4px;
  left: 4px;
  border-radius: 8px / 16px 16px 8px 8px;
  padding-top: 6px;
  width: calc(100% - 8px);
  height: calc(100% - 10px);
  box-sizing: content-box;
  background-color: grey;
  background-image: radial-gradient(4px 8px at 4px calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(4px 8px at calc(100% - 4px) calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(16px at -4px 0, white, transparent), radial-gradient(16px at calc(100% + 4px) 0, white, transparent);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5), inset 0 -1px 3px 3px rgba(0, 0, 0, .4);
  transition-property: border-radius, padding-top;
  transition-duration: .2s;
}

.source__button:active .source__button-bottom {
  border-radius: 10px 10px 8px 8px / 8px;
  padding-top: 0;
}

.source__button-base {
  position: absolute;
  z-index: -2;
  top: 4px;
  left: 0;
  border-radius: 12px;
  width: 100%;
  height: calc(100% - 4px);
  background-color: rgba(0, 0, 0, .15);
  box-shadow: 0 1px 1px 0 rgba(255, 255, 255, .75), inset 0 2px 2px rgba(0, 0, 0, .25);
}

/* ################################################################
                                                                  #
  MEDIA QUERIES                                                   # 
                                                                  #
  #################################################################
   */

@media only screen and (max-width: 480px) {
  .header-container {
    margin: auto;
    width: 100%;
    height: 40px;
    background-image: url("../../assets/img/bg_tuner-surround_2.png");
    border-radius: 4px;
  }
  
  .header__top-row { 
    display: grid;
    align-items: top;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    padding: 4px 0px
  }
  
  .header__clock {
    display: none;
  }
  
  .header__logo {
    padding: 0px 4px;
  }
  
  .header__logo > img {
    height: 32px;
    border-radius: 4px
  }
  
  .header__login {
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px 4px;
    color: whitesmoke;
  }
  
  .header__source-container {
    margin: auto;
    padding: 4px 0px 4px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}  






@media only screen and (min-width: 480px) and (max-width: 768px) {

  .header-container {
    margin: auto;
    width: 100%;
    height: 50px;
    background-image: url("../../assets/img/bg_tuner-surround_2.png");
    border-radius: 4px;
  }
  
  .header__top-row { 
    display: grid;
    align-items: top;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    padding: 4px 0px
  }
  
  .header__clock {
    font-family: alarm_clock, serif;
    padding: 0px 8px;
    text-align: center;
    font-size: 36px;
    color: whitesmoke;
  }
  
  .header__logo {
    padding: 0px 4px;
  }
  
  .header__logo > img {
    height: 42px;
    border-radius: 4px
  }
  
  .header__login {
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px 4px;
    color: whitesmoke;
  }
  
  .header__source-container {
    margin: auto;
    padding: 4px 0px 4px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}  
  
  

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    .header-container {
      margin: auto;
      width: 100%;
      height: 80px;
      background-image: url("../../assets/img/bg_tuner-surround_2.png");
      border-radius: 4px;
    }
    
    .header__top-row { 
      display: grid;
      align-items: top;
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
      padding: 4px 0px
    }
    
    .header__clock {
      font-family: alarm_clock, serif;
      padding: 0px 8px;
      text-align: center;
      font-size: 48px;
      color: whitesmoke;
    }
    
    .header__logo {
      padding: 0px 4px;
    }
    
    .header__logo > img {
      height: 72px;
      border-radius: 4px
    }
    
    .header__login {
      display:flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0px 4px;
      color: whitesmoke;
    }
    
    .header__source-container {
      margin: auto;
      padding: 4px 0px 4px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }


