.login { 
  display:flex;
  align-content: flex-end;
  right: 4px;
}

.login__text {
  font-size: 20px;
  font-weight: bold;
  padding: 0px 8px
}

.login__button {
  cursor: pointer;
}

.login__userProfilePicture {
  width: 36px;
  border-radius: 50%;
}

/* ################################################################
                                                                  #
  MEDIA QUERIES                                                   # 
                                                                  #
  #################################################################
   */

@media only screen and (max-width: 480px) {
  .login { 
    display:flex;
    align-content: flex-end;
    right: 4px;
  }
  
  .login__text {
    font-size: 14px;
    font-weight: bold;
    padding: 0px 8px;
  }
  
  .login__button {
    cursor: pointer;
  }
  
  .login__userProfilePicture {
    height: 32px;
    border-radius: 50%;
  }
}


@media only screen and (min-width: 480px) and (max-width: 768px) {
  .login { 
    display:flex;
    align-content: flex-end;
    right: 4px;
  }
  
  .login__text {
    font-size: 16px;
    font-weight: bold;
    padding: 0px 8px;
  }
  
  .login__button {
    cursor: pointer;
  }
  
  .login__userProfilePicture {
    height: 32px;
    border-radius: 50%;
  }
}