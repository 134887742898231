/* .display-station {
  width: 500px;
  display: grid;
  grid-template-columns: repeat(3, 300px);
} */

.display-station-container{
  width: 500px;
  display: grid;
  grid-template-columns: 100px auto;
  padding: 0px 0px 6px 0px;
}

.display-station__logo {
  border-radius: 8px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.display-station__info {
  margin: 0px 10px 0px 10px;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
}

.display-station__name {
  font-size: 18px;
  font-weight: bold;
  color: whitesmoke;
}

.display-station__hearticon {
  padding: 2px;
  height: 42px;
  width: 42px;
  object-fit: contain;
  cursor: pointer;
}

.display-station__hearticon:hover {
  scale: 1.4;
  transition-duration: 0.5s;
}

.save_info {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

/* ################################################################
                                                                  #
  MEDIA QUERIES                                                   # 
                                                                  #
  #################################################################
   */

@media only screen and (max-width: 480px) {
  .display-station-container{
    width: 100%;
    display: grid;
    grid-template-columns: 60px auto;
    padding: 0px 0px 6px 0px;
  }
  
  .display-station__logo {
    border-radius: 8px;
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
  
  .display-station__info {
    margin: 0px 10px 0px 10px;
    height: 60px;
    display: grid;
    grid-template-rows: auto;
  }
  
  .display-station__name {
    font-size: 12px;
    font-weight: bold;
    color: whitesmoke;
  }
  
  .display-station__hearticon {
    height: 32px;
    width: 32px;
    object-fit: contain;
    cursor: pointer;
  }
  
  .display-station__hearticon:hover {
    scale: 1.4;
    transition-duration: 0.5s;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .display-station-container{
    width: 100%;
    display: grid;
    grid-template-columns: 60px auto;
    padding: 0px 0px 6px 0px;
  }
  
  .display-station__logo {
    border-radius: 8px;
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
  
  .display-station__info {
    margin: 0px 10px 0px 10px;
    height: 60px;
    display: grid;
    grid-template-rows: auto;
  }
  
  .display-station__name {
    font-size: 14px;
    font-weight: bold;
    color: whitesmoke;
  }
  
  .display-station__hearticon {
    height: 32px;
    width: 32px;
    object-fit: contain;
    cursor: pointer;
  }
  
  .display-station__hearticon:hover {
    scale: 1.4;
    transition-duration: 0.5s;
  }  
}



@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .display-station-container{
    width: 100%;
    display: grid;
    grid-template-columns: 80px auto;
    padding: 0px 0px 6px 0px;
  }
  
  .display-station__logo {
    border-radius: 8px;
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
  
  .display-station__info {
    margin: 0px 10px 0px 10px;
    height: 80px;
    display: grid;
    grid-template-rows: auto;
  }
  
  .display-station__name {
    font-size: 18px;
    font-weight: bold;
    color: whitesmoke;
  }
  
  .display-station__hearticon {
    height: 36px;
    width: 36px;
    object-fit: contain;
    cursor: pointer;
  }
  
  .display-station__hearticon:hover {
    scale: 1.4;
    transition-duration: 0.5s;
  }  

}